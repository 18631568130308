import React, { useState, createRef } from "react";
import "../css/index.css";
// import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import { DatePicker } from '@material-ui/pickers';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ContactForm() {

    const [form, setForm] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        info: ''
    });
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    };

    const recaptchaRef = createRef();

    function clearForm() {
        setForm({
            name: '',
            phone: '',
            email: '',
            subject: '',
            info: ''
        });
    };
    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    function handleSubmit(e) {
        let item = form;
        if (!item.subject) {
            item.subject = 'Message from Westbank Rotary Contact Form';
        }
        if(recaptchaRef.current.getValue())
        {
            if (form.name && form.info) {
                fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "contact", ...form })
                })
                    .then(() => { alert("Form Submitted!"); clearForm(); })
                    .catch(error => alert(error));
            }
            else { form.info ? alert('Error: No name entered') : alert('Error: No Message!'); }
        } 
        else { alert('Error: Bad CAPTCHA'); }
        e.preventDefault();
    };
    return (
        <form onSubmit={handleSubmit} autoComplete='off' >{/*style={{ display: 'flex', flexWrap: 'wrap' }}*/}
            <div className='contact-form'>
                <div className='maxitem' ><h1>Contact Form</h1></div>
                <input type='hidden' name='form-name' value='contact'></input>
                <TextField margin='normal' className='contact-items' variant='outlined' type='text' name='name' label='Your Name' value={form.name} onChange={handleChange} />
                <TextField margin='normal' className='contact-items' variant='outlined' type='text' name='phone' label='Phone' value={form.phone} onChange={handleChange} />
                <TextField margin='normal' className='contact-items' variant='outlined' type='text' name='email' label='Email' value={form.email} onChange={handleChange} />
                <TextField margin='normal' className='contact-items' variant='outlined' type='text' name='subject' label='Subject' value={form.subject} onChange={handleChange} />
                <TextField margin='normal' variant='outlined' type='text' name='info' label='Your Message' value={form.info} onChange={handleChange} multiline fullWidth />
            </div><br />
            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />
            <br />
            <Button type='submit' color='primary' variant='outlined'>Submit</Button>
        </form>
    );
};