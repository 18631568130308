import React from "react";

import Card from '@material-ui/core/Card';

import NewsItem from '../components/news_item';
import NewsImg from '../components/news_item_img';
import '../css/news.css';

export default function News() {
    const rfr_text = 'We are so proud of these students! They participated in the Jefferson Parish Rewards for Reading Program at Laureate Academy.';
    const teachers_grants = "Can we just tell you how much we love giving out teacher's grants? Today our Rotarian Ryan Cox stopped by Geraldine Boudreaux Elementary School to award Coach Luis Omana, Paul J. Solis Elementary to award Cynthia Bankston and Mariden Emery, C.T. Janet Elementary to award Ashley Arabie, Allen Ellender School to award Cathryn Hamilton, and Bonella A. St. Ville Elementary to award Coach Seth Lunquist! Thank you so much teachers for all that you do! We love you!!";
    const cafe_hope = 'Helping around the community. Special thanks to Cafe Hope';
    const tgving2020 = 'Members help pack Thanksgiving Baskets that were distributed throughout the area to those in need.';
    const bmx21txt = "Rotary Club of the Westbank helped volunteer at BMX National Event making this a wonderful success.  Thanks to all others who helped too: Gulf Coast Bank, Gretna Police, GEDA, Optimist Club, Knights of Columbus and many others.";

    function importAll(r) {
        return r.keys().map(r);
    }
    const installation2020img = importAll(require.context('../images/installation/2020/', false));
    const installation2020 = installation2020img.map((img) => {
        return (<NewsImg header='' newClass='mediumitem' date='6-20-2020' image={img} />)
    })

    const bmx21img = importAll(require.context('../images/bmx-event-21', false));
    const bmx21 = bmx21img.map((img) => {
        return (<NewsImg header='' newClass='mediumitem' body={bmx21txt} date='4-9-2021' image={img} />)
    })

    const christmas_baskets_2020_img = importAll(require.context('../images/christmas-baskets/2020', false));
    const cbaskets20 = christmas_baskets_2020_img.map((img) => {
        return (<NewsImg header='' newClass='mediumitem' body='Christmas basket giveaway partnering with Boston Club Westbank and donation of $5000' date='' image={img} />)
    })

    return (
        <div className='flexbox-container' style={{ justifyContent: 'center' }}>
            <Card className='maxitem'>
                <h1>News</h1>
            </Card>
            <div className = 'flexbox-container news-list'>
                <NewsItem
                  className='maxitem card'
                  raised
                  header='2023 Scholarship Recipients'
                  body={<>
                      <span style={{justifyContent: 'center', width: '100%'}}>Congratulations to our 2023 Scholarship Recipients!</span>
                        <div style={{fontWeight: 'bold'}}>
                           Jason Brown<br />
                           Angelina Hoover<br />
                           Jose Laguerre<br />
                       </div>
                     </>}
                ></NewsItem>
                <NewsItem
                  className='maxitem card'
                  raised
                  header='2022 Scholarship Recipients'
                  body={<>
                      <span style={{justifyContent: 'center', width: '100%'}}>Congratulations to our 2022 Scholarship Recipients!</span>
                        <div style={{fontWeight: 'bold'}}>
                          Renzo Cantin<br />
                          McKenna Dufrene<br />
                          Julia Flood<br />
                          Spencer Protti<br />
                          Aidan Schaubhut<br />
                        </div>
                  </>}
                ></NewsItem>
                <Card className='maxitem card' raised><h1>City of Gretna Certificate</h1></Card>
                <NewsImg header='' newClass='mediumitem' date='' body="This outstanding group has been awarded certificates from City of Gretna for volunteering for Gretna BMX National Qualifier.
                From left to right: Councilman Mark Miller, Amie Hebert with Gretna Recreation Department, Mayor Belinda Constant then are the Rotarians: Maria Ramirez, Jim Blazek, Kevin Avery, Councilman Rudy Smith & Blake Lawson" image={require('../images/city-of-gretna-award-2021.jpg')} />

                <Card className='maxitem card' raised><h1>2020 Christmas Baskets</h1></Card>
                {cbaskets20}

                <Card className='maxitem card' raised><h1>2021 Bayou Classic Nationals</h1></Card>
                {bmx21}

                <Card className='maxitem card' raised><h1>Thanksgiving Baskets 2020</h1></Card>
                <NewsImg header='' newClass='mediumitem' date='11-24-2020' body={tgving2020} image={require('../images/thanksgiving_baskets/2020/IMG_0908.JPG')} />
                <NewsImg header='' newClass='mediumitem' date='11-24-2020' body={tgving2020} image={require('../images/thanksgiving_baskets/2020/IMG_0909.JPG')} />

                <Card className='maxitem card' raised><h1>Cafe Hope Sign</h1></Card>
                <NewsImg header=''
                    body={<>We are proud to assist in funding a new sign and extension of the patio for <a href="https://www.facebook.com/CafeHopeNOLA/">Cafe Hope</a></>}
                    newClass='mediumitem'
                    date='11-3-2020'
                    image={require('../images/cafe_hope_sign/1.jpg')} />

                <Card className='maxitem card' raised><h1>2020 Installation</h1></Card>
                {installation2020}

                <Card className='maxitem card' raised><h1>Cafe Hope</h1></Card>
                <NewsImg header='' newClass='mediumitem' date='4-15-2020' body={cafe_hope} image={require('../images/cafe_hope/IMG_3058.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='4-15-2020' body={cafe_hope} image={require('../images/cafe_hope/IMG_3060.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='4-15-2020' body={cafe_hope} image={require('../images/cafe_hope/IMG_3065.jpg')} />

                <Card className='maxitem card' raised><h1>Teacher's Grants</h1></Card>
                <NewsImg header='' newClass='mediumitem' date='2-12-2020' body={teachers_grants} image={require('../images/teachers_grants/1.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='2-12-2020' body={teachers_grants} image={require('../images/teachers_grants/2.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='2-12-2020' body={teachers_grants} image={require('../images/teachers_grants/3.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='2-12-2020' body={teachers_grants} image={require('../images/teachers_grants/4.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='2-12-2020' body={teachers_grants} image={require('../images/teachers_grants/5.jpg')} />
                <NewsImg header='' newClass='mediumitem' date='2-13-2020' body={'BIG SMILES!! Rotarian Ryan Cox was delivering teachers grants again today! This time dropping of the awards to Jeremy Williams and Sara Langley at Marrero Middle School'} image={require('../images/teachers_grants/6.jpg')} />
                {/* <NewsImg header='' newClass='mediumitem' body={teachers_grants} image={require('../images/teachers_grants/7.jpg')} /> */}
                {/* <NewsItem header='Check here for news!' body='test' date='2-1-2020' /> */}
                <Card className='maxitem card' raised><h1>Rewards for Reading</h1></Card>
                <NewsImg header='Rewards for Reading' newClass='mediumitem' body={rfr_text} image={require('../images/rfr/IMG_3809.JPG')} />
                <NewsImg header='Rewards for Reading' newClass='mediumitem' body={rfr_text} image={require('../images/rfr/IMG_3816.JPG')} />
                <NewsImg header='Rewards for Reading' newClass='mediumitem' body={rfr_text} image={require('../images/rfr/IMG_3820.JPG')} />
                <NewsImg header='Rewards for Reading' newClass='mediumitem' body={rfr_text} image={require('../images/rfr/IMG_3822.JPG')} />
                {/* <NewsItem header='Title' body='some other news item' date='' /> */}
            </div>
        </div>
    );
}