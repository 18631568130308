import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default function NewsImg(props) {
    const newClass = props.newClass ? props.newClass : 'xsmallitem';
    return (<Card className={newClass} raised>
        <CardContent className=''>
            <h1>{props.header}</h1>
            <img style={{ maxHeight: '500px', maxWidth: '100%' }} src={props.image} alt='' />
            <h3>{props.date}</h3>
            <div className='body2'>{props.body}</div>
        </CardContent>
    </Card>);
};