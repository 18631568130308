import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import MeetingTime from '../components/meeting_blurb';
import Scholarship from '../components/scholarship';
import MembershipForm from '../components/membership_form';
export default function About() {

    return (
        <div className='flexbox-container'>
            <Card className='xsmallitem' raised>
                <CardContent className=''>
                    <MeetingTime />
                    <Scholarship />
                </CardContent>
            </Card>
            <Card className='bigitem' raised>
                <CardContent>
                    <h1>About Us</h1>
                    This vibrant and growing club, founded in 1936 as club number 3995, continues to contribute Manpower and financial resources to Westbank and Greater New Orleans area community projects and service organizations.<br /><br />
                    The business and professional leaders who make up our membership believe in service above self to the community. This club is a member of Rotary International and of Rotary District 6840.<br /><br />
                    For the latest club news, email our president to get on our weekly newsletter email list. You can download past issues of our award-winning, weekly newsletter The Rotator.
                    <h1>Interested in Joining?</h1>
                    Fill out the form below!
                </CardContent>
            </Card>
            <Card className='bigitem' raised>
                <CardContent>
                    <MembershipForm />
                </CardContent>
            </Card>
        </div>
    );
};