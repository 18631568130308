import React from 'react';
import { Route, BrowserRouter, Link as RouterLink } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from "@material-ui/core/Drawer";
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Home from "../views/Home";
import About from "../views/About";
import News from "../views/News";
import Contact from "../views/Contact";

export default function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const[collapse, setMobileOpen] = React.useState(false);
  // const[mobileOpen, setMobileOpen] = React.useState(false);
  // const [anchorEl, setanchorEl] = React.useState(null);

  const menuButtonCSS = { textTransform: 'none', textAlign: 'center', padding: '10px', marginRight: '5px', fontWeight: 'bold', fontSize: '2.4em' };
  const drawerButtonCSS = { textTransform: 'none', width: '100%', marginTop: '15px' }
  const theme = createMuiTheme({
    palette: { primary: { main: '#005daa' }, secondary: { main: '#febd11' } }
  });

  function handleDrawerToggle() { setMobileOpen(!mobileOpen); };
  function handleDrawerClickAway() { setMobileOpen(false); }
  function drawer(variant) {
    return (<Drawer
      variant={variant}
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
    >
      <List style={{}}>
        <ListItem>
          <Button style={drawerButtonCSS} onClick={handleDrawerClickAway} component={RouterLink} to='/'>
            <ListItemText primary="Home" />
          </Button>
        </ListItem>
        <ListItem>
          <Button style={drawerButtonCSS} onClick={handleDrawerClickAway} component={RouterLink} to='/About'>
            <ListItemText primary="About" />
          </Button>
        </ListItem>
        {/* <ListItem>
          <Button style={drawerButtonCSS} onClick={handleDrawerClickAway} component={RouterLink} to='/Membership'>
            <ListItemText primary="Membership" />
          </Button>
        </ListItem> */}
        <ListItem>
          <Button style={drawerButtonCSS} onClick={handleDrawerClickAway} component={RouterLink} to='/News'>
            <ListItemText primary="News" />
          </Button>
        </ListItem>
        <ListItem>
          <Button style={drawerButtonCSS} onClick={handleDrawerClickAway} component={RouterLink} to='/Contact'>
            <ListItemText primary="Contact" />
          </Button>
        </ListItem>
      </List>
    </Drawer>);
  };
  function menu() {
    return (
      <Toolbar style={{ backgroundColor: '', textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
        <img src={require('../images/logos/transparent-w-text.png')} alt='' style={{
          marginRight:     'auto',
          width:           '20%',
          backgroundColor: 'white',
          borderRadius:    '5px',
          padding:         '0px 5px'
        }} />
        <Button style={menuButtonCSS} color='secondary' component={RouterLink} to='/'>
          {/* <ListItemText primary="Home" /> */}Home
        </Button>
        <Button style={menuButtonCSS} color='secondary' component={RouterLink} to='/About'>
          {/* <ListItemText primary="About" /> */}About
        </Button>
        {/* <Button style={menuButtonCSS} color='secondary' component={RouterLink} to='/Membership'>
          {/* <ListItemText primary="Membership" /> */}{/*Membership
        </Button> */}
        <Button style={menuButtonCSS} color='secondary' component={RouterLink} to='/News'>
          {/* <ListItemText primary="News" /> */}News
        </Button>
        <Button style={menuButtonCSS} color='secondary' component={RouterLink} to='/Contact'>
          {/* <ListItemText primary="Contact" /> */}Contact
        </Button>
      </Toolbar>
    );
  };
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppBar position="sticky" color='primary'>
          <Hidden mdUp>
            <Toolbar className='menuBar' style={{ width: '', display: 'flex', justifyContent: 'space-between' }}>
              <IconButton color="inherit" aria-label="Menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <img src={require('../images/logos/transparent-w-text.png')} alt='' style={{
                width:           '50%',
                padding:         '10px 3px',
                backgroundColor: 'white',
                borderRadius:    '5px' }} />
            </Toolbar>
            {drawer('temporary')}
          </Hidden>
          <Hidden smDown>
            {menu()}
          </Hidden>
        </AppBar>
        <div className='content'>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Route exact path="/" component={Home} />
            <Route path="/About" component={About} />
            <Route path="/News" component={News} />
            {/* <Route path="/Membership" component={Membership} />*/}

          <Route path="/Contact" component={Contact} />
          </MuiPickersUtilsProvider>
        </div>
        <div className='footer'>2024 Rotary Club of the West Bank. All Rights Reserved.</div>
      </ThemeProvider>
    </BrowserRouter>
  );
}
