import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import ContactForm from '../components/contact_form';
export default function Contact() {

    return (
        <div className='flexbox-container'>
            <Card className='smallitem' raised>
                <CardContent style={{ textAlign: 'center' }}>
                    <h1>Mailing Address: P.O. Box 3407 Gretna, LA 70054</h1>
                </CardContent>
            </Card>
            <Card className='maxitem' style={{ margin: '10px 5px' }} raised>
                <CardContent>
                    <ContactForm />
                </CardContent>
            </Card>
        </div>
    );
};