import React, { useState, createRef } from "react";

import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from 'react-google-recaptcha';

export default function MembershipForm() {

    const [form, setForm] = useState({
        name: '',
        company: '',
        address: '',
        phone: '',
        email: '',
        today: moment().format('MMMM DD, YYYY'),
        birthdate: moment().format('MMMM DD, YYYY'),
        host: '',
        host_phone: '',
        host_email: '',
        info: ''
    });

    const recaptchaRef = createRef();

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    };
    function clearForm() {
        setForm({
            name: '',
            company: '',
            address: '',
            phone: '',
            email: '',
            today: moment().format('MMMM DD, YYYY'),
            birthdate: moment().format('MMMM DD, YYYY'),
            host: '',
            host_phone: '',
            host_email: '',
            info: ''
        });
    };
    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    };
    function handleSubmit(e) {
        if(recaptchaRef.current.getValue())
        {
            if (form.name) {
                fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "membership", ...form })
                })
                    .then(() => { alert("Form Submitted!"); clearForm(); })
                    .catch(error => alert(error));
            }
            else { alert('Error: No name entered') }
        }
        else { alert('Error: Bad CAPTCHA'); }
        e.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit} autoComplete='off' >
            <div className='contact-form' style={{ justifyContent: 'space-between' }}>
                <div className='maxitem' ><h1>Membership Form</h1></div>
                <input type='hidden' name='form-name' value='membership'></input>
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='name' label='Your Name' value={form.name} onChange={handleChange} /> <br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='company' label='Company' value={form.company} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='address' label='Address' value={form.address} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='phone' label='Phone' value={form.phone} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='email' label='Email' value={form.email} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' inputProps={{ readOnly: true }} variant='outlined' type='text' name='today' label='Current Date' value={form.today} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='birthdate' label='Birth Date' value={form.birthdate} onChange={handleChange} /><br />
                {/* <DatePicker name='birthdate' margin='normal' className='membership-items' style={{ marginRight: '2%' }} variant='inline' inputVariant='outlined' label='Birthday' format='MMMM DD, YYYY'
                    value={form.birthdate} onChange={(e) => { setForm({ ...form, birthdate: e.format('MMMM DD, YYYY') }) }} /> */}
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='host' label='Host Name' value={form.host} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='host_phone' label='Host Phone' value={form.host_phone} onChange={handleChange} /><br />
                <TextField margin='normal' className='membership-items' variant='outlined' type='text' name='host_email' label='Host Email' value={form.host_email} onChange={handleChange} /><br />
                <TextField margin='normal' variant='outlined' type='text' name='info' label='Tell us about yourself' value={form.info} onChange={handleChange} multiline fullWidth /><br />
            </div><br />
            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />
            <br />
            <Button type='submit' color='primary' variant='outlined'>Submit</Button>
        </form>
    );
};