import React from 'react';
export default function MeetingTime() {
    return (
        // <Card className='xsmallitem' raised>
        //     <CardContent className=''>
        <React.Fragment>
            <h1>Meetings:</h1>
            <div className='body2'>Club meetings of our 60 members are <br />Tuesdays at 12:15<br />at KC Hall in 475 Franklin St, Gretna, LA 70053</div>
            <h1>General Information:</h1>
            <div className='body2'>Interested in getting involved in the Rotary Club of the Westbank?</div>
            <h1>Mailing Address:</h1>
            <div className='body2'>P.O. Box 3407 Gretna, LA 70054</div>
        </React.Fragment>
        // </CardContent>
        // </Card>
    );
};